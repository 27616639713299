import React from 'react';

// Style
import './style.scss';

const Button = ({color, children}) => {
  return (
    <div className={`buttonContainer ${color}`}>
      <p>{children}</p>
    </div>
  );
};

export default Button;