import React, { useState, useEffect, useRef } from 'react';

// Datas
import arrayJob from './data';

//Images
import underline from '../../images/bigunderline.svg';

// Style
import './style.scss';


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const ForWho = ({setHeaderColor, setSelectedComponent}) => {
  const refContainer = useRef(null);

  const [jobSelected, setJobSelected] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(1280);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, []);

  const handleScroll = () => {
    if(refContainer.current && refContainer.current.getBoundingClientRect().y < 93) {
      setHeaderColor('white');
      setSelectedComponent(1);
    }
  }

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }

  return (
    <div className="forWhoContainer container" id='forWho' ref={refContainer}>
      <div className="titles">
        <h2>Pour qui ?</h2>
        <img src={underline} alt="underline"/>
      </div>
      {windowWidth > 1280 &&
      <div className="jobList">
        {arrayJob.map((item, key) => {
          return (
            <div className={jobSelected === key ? "job selected" : "job"} key={key} onClick={() => setJobSelected(key)}>
              {item.name}
            </div>
          )
        })}
      </div>
      }
      {windowWidth < 1280 &&
      <div className={selectOpen ? "jobListSelect open" : "jobListSelect"}>
        <div className={selectOpen ? "job selected selectOpen" : "job selected"} onClick={() => setSelectOpen(!selectOpen)}>
          <p>{arrayJob[jobSelected].name}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.645" viewBox="0 0 14 8.645">
            <g id="ic_keyboard_arrow_right_24px" transform="translate(14) rotate(90)">
              <path id="Tracé_23" data-name="Tracé 23" d="M8.59,18.1l5.343-5.355L8.59,7.395,10.235,5.75l7,7-7,7Z" transform="translate(-8.59 -5.75)" fill="#2c2f37"/>
            </g>
          </svg>
        </div>
        {arrayJob.map((item, key) => {
            if(jobSelected !== key) {
              return (
                <div className={"job option"} key={key} onClick={() => {setJobSelected(key); setSelectOpen(false)}}>
                  {item.name}
                </div>
              )
            }
          })
        } 
      </div>
      }

      <div className="jobAdvantages">
        <h3>{arrayJob[jobSelected].name}</h3>
        <p className="catchphrase">{arrayJob[jobSelected].catchphrase}</p>
        <p className="descriptionJob">
        {arrayJob[jobSelected].description}
        </p>
        <p className="catchphrase catchphrase2">{arrayJob[jobSelected].catchphrase2}</p>
        <p className="descriptionJob">
        {arrayJob[jobSelected].description2}
        </p>
      </div>
    </div>
  );
};

export default ForWho;