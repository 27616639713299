import React from 'react';

// Style
import './style.scss';

const ButtonArrow = ({children}) => {
  return (
    <div className={`buttonArrowContainer`}>
      <p>{children}</p>
    </div>
  );
};

export default ButtonArrow;