import React, { useState, useRef, useEffect } from 'react';
import { Link } from "gatsby";


// Style
import './style.scss';

// Images
import bottomArrow from '../../images/bottomArrow.svg'
import underline from '../../images/bigunderline.svg';
import ButtonArrow from '../../elements/ButtonArrow/index'

// Datas
import arrayFAQ from './data';

const arrayOffer = [
  'Général',
  'Spot +',
  'Premium',
]


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}



const FAQ = ({setHeaderColor, setSelectedComponent, faqOffer}) => {
  const refContainer = useRef(null);

  const [questionOpened, setQuestionOpened] = useState(-1);
  const [offerSelected, setOfferSelected] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(1280);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, []);

  useEffect(() => {
    setOfferSelected(faqOffer[0]);
    setQuestionOpened(faqOffer[1]);
  }, [faqOffer]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleScroll = () => {
    if(refContainer.current && refContainer.current.getBoundingClientRect().y < 93) {
      setHeaderColor('white');
      setSelectedComponent(3);
    }
  }

  const handleQuestionClick = (key) => {
    if(key === questionOpened) {
      setQuestionOpened(-1);
    }
    else {
      setQuestionOpened(key);
    }
  }

  return (
    <div className="faqContainer" id='faq' ref={refContainer}>
      <div className="faqHeader">
        <div className="titles">
          <h2>Nos réponses à vos questions</h2>
          <img src={underline} alt="underline"/>
        </div>
        {windowWidth >=900 &&
          <div className="buttons">
            <button className={offerSelected === 0 ? 'btnSelected' : ''} onClick={() => {setOfferSelected(0); setQuestionOpened(-1)}}> Général </button>
            <button className={offerSelected === 1 ? 'btnSelected' : ''} onClick={() => {setOfferSelected(1); setQuestionOpened(-1)}}> Spot</button>
            <button className={offerSelected === 2 ? 'btnSelected' : ''} onClick={() => {setOfferSelected(2); setQuestionOpened(-1)}}> Professionnal </button>
          </div>
        }
        
        {windowWidth < 900 &&
          <div className={selectOpen ? "offerSelect open" : "offerSelect"}>
            <div className={selectOpen ? "offer selected selectOpen" : "offer selected"} onClick={() => setSelectOpen(!selectOpen)}>
              <p>{arrayOffer[offerSelected]}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.645" viewBox="0 0 14 8.645">
                <g id="ic_keyboard_arrow_right_24px" transform="translate(14) rotate(90)">
                  <path id="Tracé_23" data-name="Tracé 23" d="M8.59,18.1l5.343-5.355L8.59,7.395,10.235,5.75l7,7-7,7Z" transform="translate(-8.59 -5.75)" fill="#2c2f37"/>
                </g>
              </svg>
            </div>
            {arrayOffer.map((item, key) => {
                if(offerSelected !== key) {
                  return (
                    <div className={"offer option"} key={key} onClick={() => {setOfferSelected(key); setSelectOpen(false)}}>
                      {item}
                    </div>
                  )
                }
              })
            } 
          </div>
        }
      </div>      
      <div className="questions">
        {offerSelected !== 2 ? arrayFAQ[offerSelected].map((item, key) => {
          return (
            <div className={key === questionOpened ? "question questionOpen" : "question"} key={key} >
              <div className="questionVisible" onClick={() => handleQuestionClick(key)}>
                <p className="name" >{item.question}</p>
                <img src={bottomArrow} alt="arrow" />
              </div>
              {offerSelected === 0 && key > 1 ?
                <p className="answer" dangerouslySetInnerHTML={{__html:item.answer}}>
                </p> 
              :
                <p className="answer">
                  {item.answer}
                  {offerSelected === 0 && key === 0 && <p>Découvrir l'équipe sur <a href="https://fr.linkedin.com/company/scorf" target="_blank">Linkedin</a></p>}
                </p>          
              }
            </div>
          )
        })
      : 
        <div className="premium">
          <p className='catchPhrase'>Nous serons ravis de répondre à toutes vos questions de vive voix</p>
          <Link to='/#contact'><ButtonArrow>Nous contacter</ButtonArrow></Link>
        </div>
      
      }
        
      </div>
    </div>
  );
};

export default FAQ;