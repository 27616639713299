import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com'
import apiKeys from '../../apikeys'

// Style
import './style.scss';

import SmallLoader from '../SmallLoader';

// Images
import contact from '../../images/contact.png';
import check from '../../images/check.svg';
import bpi from '../../images/bpi.png';
import reseau from '../../images/reseau.png';
import underline from '../../images/bigunderline.svg';


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Contact = ({ setHeaderColor, setSelectedComponent }) => {

  const refContainer = useRef(null)
  const refImage = useRef(null)
  const mailConfirmationRef = useRef('');
  const contactInfoRef = useRef({ mail: '', phone: '' });

  const [submitMessage, setSubmitMessage] = useState('notSent');
  const [contactInfo, setContactInfo] = useState({
    mail: '',
    phone: '',
  })
  const [mailConfirmation, setMailConfirmation] = useState('');
  const [load, setLoad] = useState(false);


  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    const form = document.querySelector('.form')
    form.addEventListener('submit', sendEmail)
  }, []);

  useEffect(() => {
    contactInfoRef.current = contactInfo;
  }, [contactInfo]);

  useEffect(() => {
    mailConfirmationRef.current = mailConfirmation;
  }, [mailConfirmation])

  const handleScroll = () => {
    if (refContainer.current) {
      if (refContainer.current.getBoundingClientRect().y < 93) {
        setHeaderColor('dark');
        setSelectedComponent(4);
      }
      if (refImage.current && refContainer.current.getBoundingClientRect().y < 350 && refContainer.current.getBoundingClientRect().y > -400) {
      }
    }
  }

  const sendEmail = e => {
    e.preventDefault()
    if (contactInfoRef.current.mail !== mailConfirmationRef.current) {
      setSubmitMessage('emailNotIdentical')
    }
    else {
      setLoad(true);
      emailjs
        .sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
        .then(
          result => {
            setLoad(false);
            setSubmitMessage('Success')
          },
          error => {
            setLoad(false);
            setSubmitMessage('Error')
          }
        )
        .finally(() => {
          setContactInfo({
            mail: '',
            phone: '',
          })
          setMailConfirmation('')
        })
    }
  }

  return (
    <div className="container contactContainer" id='contact' ref={refContainer}>
      <div className="content">
        <div className="leftContent">
          <div className="titles">
            <h2>Découvrir Scorf ?</h2>
            <img src={underline} alt="underline" />
            <p className='subtitle'>Contactez notre équipe !</p>
          </div>
          <div className="feature">
            <img src={check} alt="check" />
            <p>Planifiez une démo</p>
          </div>
          <div className="feature">
            <img src={check} alt="check" />
            <p>Obtenir des précisions sur nos offres</p>
          </div>
          <div className="feature">
            <img src={check} alt="check" />
            <p>Détaillez vos besoins et bénéficiez de notre expertise</p>
          </div>
          <div className="partners">
            <img src={bpi} alt="bpi" />
            <img src={reseau} alt="reseau" />
          </div>
        </div>
        <div className="rightContent">
          <h3>Nous vous rappelons !</h3>
          <form action="" method='POST' className='form'>
            <div className="inputContainer">
              <label>Adresse Mail <span>*</span></label>
              <input type="email" name="mail" required placeholder='Votre Adresse Mail' value={contactInfo.mail} onChange={(e) => { setContactInfo({ mail: e.target.value, phone: contactInfo.phone }); setSubmitMessage('notSent') }} />
            </div>
            <div className="inputContainer">
              <label>Confirmation Adresse Mail <span>*</span></label>
              <input type="email" name="confirmation_mail" required placeholder='Confirmez Votre Adresse Mail' value={mailConfirmation} onChange={(e) => { setMailConfirmation(e.target.value); setSubmitMessage('notSent') }} />
            </div>
            <div className="inputContainer">
              <label>Téléphone</label>
              <input type="text" name="phone" placeholder='Votre numéro de téléphone' value={contactInfo.phone} onChange={(e) => { setContactInfo({ phone: e.target.value, mail: contactInfo.mail }); setSubmitMessage('notSent') }} />
            </div>
            <div className="submit">
              <div className="submitMessage">
                {submitMessage === 'emailNotIdentical' &&
                  <p className='error'>Vos adresses mail ne sont pas identiques </p>
                }
                {submitMessage === 'Error' &&
                  <p className='error'>Une erreure est sruvenue</p>
                }
                {submitMessage === 'Success' &&
                  <p className='success'>Le message a été envoyé correctement ! </p>
                }
                {load &&
                  <SmallLoader></SmallLoader>
                }
              </div>
              <input id='submitButton' type='submit' value='PARLONS NOUS !' />
            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default Contact;