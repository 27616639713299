const arrayJob = [
  {
    name:"Dirigeants et Directeurs financiers",
    catchphrase: 'Obtenez à tout moment en quelques clics une vision détaillée et actualisée de votre entreprise',
    description: `Transformez les FECs obtenus auprès de votre expert-comptable ou directement extraits de votre système, en états financiers détaillés et dynamiques : découvrez votre performance annuelle le 5 janvier plutôt que le 5 mai, simplifiez-vous les demandes de prêts bancaires et les présentations aux actionnaires ! `,
    catchphrase2: 'Soyez autonomes dans vos opérations de croissance externe ou de reprise d’entreprise',
    description2: `Que vous soyez un dirigeant à la recherche de cibles pour des opérations de croissance externe ou un repreneur indépendant, Scorf est la seule solution qui vous permettra de poser un premier diagnostic sur la performance financière de votre cible en quelques minutes.
    \nRécupérez les Grands-Livres, Balances Générales ou FEC de l’entreprise, importez-les tels quels dans votre workspace sécurisé auquel vous seul avez accès : en trois clics les P&L, Bilan et Cash-Flow sont disponibles pour commencer immédiatement la revue analytique. 
    `
  },
  {
    name:'Transaction Services et Restructuring',
    catchphrase: 'Gagnez au moins une 1 journée par mission',
    description: `En buy-side ou en VDD, récupérez les Grands-Livres, Balances Générales ou FEC de l’entreprise cible et importez-les tels quels dans votre workspace sécurisé auquel vous seul avez accès : 
    \n    - En trois clics les P&L, Bilan et Cash-Flow sont disponibles pour commencer immédiatement la revue analytique,
       poser les bonnes questions, identifier les dépenses ou les schémas comptables qui devront être éclaircis.
    \n    - Et bien sûr, exportez l’ensemble dans Excel pour constituer un pack de data sur la base duquel lancer les analyses de fond. 
    `
  },
  {
    name:'Experts-Comptables',
    catchphrase: 'Ajoutez de nouvelles offres à votre expertise',
    description: `Afin de faire face aux changements technologiques et législatifs à venir, la profession dispose de deux leviers de préparation : se mettre à niveau sur le plan technologique et accroître la valeur ajoutée de ses services.
    \nEt c’est précisément ce à quoi œuvre Scorf : permettre aux experts-comptables de réaliser des due diligences d’acquisition sans passer deux jours à traiter des fichiers Excel déstructurés ; accompagner un cédant ; offrir à ses clients à chaque clôture un datapack analytique détaillé qui complétera parfaitement la bonne vieille plaquette des états financiers en PDF.  
    `
  },
  {
    name:"Fonds d'investissement / M&A smid cap",
    catchphrase: 'Accélérez les processus',
    description: `Les opérations d’investissement small et mid cap – LBO, levée de fonds, M&A, ne nécessitent pas systématiquement l’intervention d’un conseil financier, que ce soit pour des raisons de budget, de temps ou de taille.
    \nDans tous ces cas, Scorf permettra d’agir vite et efficacement : répondre aux attentes d’un chargé d’affaires en M&A - qui doit poser les premiers chiffres de son teaser - ou en fonds - qui doit se positionner rapidement sur un éventuel investissement et préparer une présentation pour le comité d’investissement .  
    `
  },
]

export default arrayJob