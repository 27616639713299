const arrayOffer = [
  {
    type: 'spot',
    title: 'Spot',    
    subtitle: 'Pour les fonctionnalités basiques sans accès à l’application',
    price: "250",
    details: "Selon le nombre d'entités et d'années",
    features: [
      'Un Datapack détaillé',
      'Service client constitué d’experts en Finance d’Entreprise',
    ]
  },
  {
    type: 'premium',
    title: 'Professional',
    subtitle: 'Pour les besoins récurrents des professionnels de la Finance',
    features: [
      'Service client constitué d’experts en Finance d’Entreprise',
    ]
  },
]

export default arrayOffer;