import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import Contact from "../components/Contact"
import FAQ from "../components/FAQ"
import Features from "../components/Features"
import Footer from "../components/Footer"
import ForWho from "../components/ForWho"
import Header from "../components/Header"
import Introduction from "../components/Introduction"
import Offer from "../components/Offer"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// markup
const IndexPage = () => {

  const [headerColor, setHeaderColor] = useState('transparent');
  const [selectedComponent, setSelectedComponent] = useState(-1);
  const [faqOffer, setFaqOffer] = useState([0, -1]);


  return (
    <main className={"site"}>      
      <Helmet title={'Scorf'}>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name='title' content='Scorf'/>
          <meta name="description" content="Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes." />
          <link rel="canonical" href="https://www.scorf.io/" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-8VFYNJHG71"></script>
          <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-8VFYNJHG71');`}}>            
          </script>
      </Helmet>
      <title>Scorf</title>
      <Header headerColor={headerColor} selectedComponent={selectedComponent}/>
      <Introduction setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
      <Features setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
      <ForWho setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
      <Offer setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} setFaqOffer={setFaqOffer} />
      <FAQ setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} faqOffer={faqOffer}/>
      <Contact setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
      <Footer setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
    </main>
  )
}

export default IndexPage
