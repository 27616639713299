import React, { useRef, useState, useEffect } from 'react';

// Style
import './style.scss';

// Images
import leftArrow from '../../images/leftArrow.png'
import explainBackground from '../../images/explainBackground.png'
import underline from '../../images/bigunderline.svg';

// Datas
import arrayFeatures from './dataFeatures';
import arrayExplain from './dataExplain';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';

SwiperCore.use([Navigation, Mousewheel])


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Features = ({setHeaderColor, setSelectedComponent}) => {

  const refSliderWrapper = useRef(null);
  const refContainer = useRef(null);
  const refCountDown = useRef(null);

  const [sliderPage, setSliderPage] = useState(1);
  const [sliderPageMobile, setSliderPageMobile] = useState(0);
  const [sliderGoesLeft, setSliderGoesLeft] = useState(false)
  const [dragStart, setDragStart] = useState(false);
  const [posInitial, setPosInitial] = useState(0);
  const [posWrapperInitial, setPosWrapperInitial] = useState(0);

  const [windowWidth, setWindowWidth] = useState(1280);

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const [explainSelected, setExplainSelected] = useState(0);
  const [explainAnim, setExplainAnim] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && windowWidth >= 1280) {
      if(seconds%10 <= 0.1) {
        // setSeconds(5);
        if(refSliderWrapper.current && sliderPage < Math.ceil((329 * arrayFeatures.length - 35) / refSliderWrapper.current.offsetParent.clientWidth) && !sliderGoesLeft) {
          handleSliderMove('right');
          setSliderPageMobile(sliderPageMobile + 1)
          if(sliderPageMobile + 1 === arrayFeatures.length - 1) {
            setSliderGoesLeft(true);
          }
        }
        else {
          if(sliderPageMobile - 1 === 0) {
            setSliderGoesLeft(false)
          }
          else {            
            setSliderGoesLeft(true);
          }
          handleSliderMove('left');
          setSliderPageMobile(sliderPageMobile - 1)
        }
      }
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 0.1);
      }, 100);
      
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    if(windowWidth >= 1280) {
      if(seconds === 0) {
        refCountDown.current.style.strokeDashoffset = 0;
        refCountDown.current.style.transitionDuration = '0.5s';
        refSliderWrapper.current.style.transitionTimingFunction = 'linear';
      }
      else {
        refCountDown.current.style.strokeDashoffset = 113 * seconds/10;
        refCountDown.current.style.transitionDuration = '0.5s';
        refSliderWrapper.current.style.transitionTimingFunction = 'linear';
      }
    }
  }, [seconds]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, []);

  useEffect(() => {
    if(explainAnim) {
      const timer = setTimeout(() => {
        if(explainSelected === arrayExplain.length - 1) {
          setExplainSelected(0);
        }
        else {
          setExplainSelected(explainSelected + 1);
        }      
      }, 10000);
    }    
    // return () => clearTimeout(timer);
  }, [explainSelected]);

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleScroll = () => {    
    if(refContainer.current && refContainer.current.getBoundingClientRect().y < 93) {
      setHeaderColor('dark');
      setSelectedComponent(0);
    }
  }

  const handleDragStart = (position) => {
    setDragStart(true);
    setPosInitial(position);
    if(refSliderWrapper.current.style.left === "") {
      setPosWrapperInitial(0)
    }
    else {
      setPosWrapperInitial(parseInt(refSliderWrapper.current.style.left.replace('px', '')))
    }
  }

  const handleDrag = (position) => {
    if(dragStart && refSliderWrapper.current) {
      refSliderWrapper.current.style.left = posWrapperInitial + (position - posInitial) + 'px';
      
      if(parseInt(refSliderWrapper.current.style.left.replace('px', '')) > 0) {
        refSliderWrapper.current.style.left = 0 + 'px';
      }

      if(windowWidth > 1280) {
        if(parseInt(refSliderWrapper.current.style.left.replace('px', '')) < ((293 + 36) * (arrayFeatures.length - 2) - 36 ) * -1 ) {
          refSliderWrapper.current.style.left = ((293 + 36) * (arrayFeatures.length - 2) - 36 ) * -1 + 'px';
        }
      }      
      
      if(windowWidth < 600) {
        if(parseInt(refSliderWrapper.current.style.left.replace('px', '')) < ((293 + 36) * (arrayFeatures.length ) - 200 ) * -1 ) {
          refSliderWrapper.current.style.left = ((293 + 36) * (arrayFeatures.length) - 2 ) * -1 + 'px';
          refSliderWrapper.current.style.transitionDuration = 0
        }
      }

      else if(windowWidth < 1280) {
        if(parseInt(refSliderWrapper.current.style.left.replace('px', '')) < ((293 + 36) * (arrayFeatures.length - 1) - 36 ) * -1 && windowWidth < 1280) {
          refSliderWrapper.current.style.left = ((293 + 36) * (arrayFeatures.length - 1) - 36 ) * -1 + 'px';
        }
      }
    }
  }

  const handleSliderMove = (direction) => {
    if(direction === 'right' && refSliderWrapper.current && sliderPage < Math.ceil((329 * arrayFeatures.length - 35) / refSliderWrapper.current.offsetParent.clientWidth)) {
      refSliderWrapper.current.style.left = refSliderWrapper.current.style.left.replace('px', '') - refSliderWrapper.current.offsetParent.clientWidth - 35 + 'px';
      refSliderWrapper.current.style.transitionDuration = '0.4s';
      refSliderWrapper.current.style.transitionTimingFunction = 'ease-in-out';
      setSliderPage(sliderPage + 1);
    }
    if(direction === 'left' && refSliderWrapper.current && sliderPage > 1 ) {
      if(parseInt(refSliderWrapper.current.style.left.replace('px', '')) + refSliderWrapper.current.offsetParent.clientWidth + 35 > 0) {
        refSliderWrapper.current.style.left = 0 + 'px';
        refSliderWrapper.current.style.transitionDuration = '0.4s';
        refSliderWrapper.current.style.transitionTimingFunction = 'ease-in-out';
      }
      else {
        refSliderWrapper.current.style.left = parseInt(refSliderWrapper.current.style.left.replace('px', '')) + refSliderWrapper.current.offsetParent.clientWidth + 35 + 'px';
        refSliderWrapper.current.style.transitionDuration = '0.4s'; 
        refSliderWrapper.current.style.transitionTimingFunction = 'ease-in-out';
      }
      setSliderPage(sliderPage - 1);
    }
  }

  const pauseLoader = () => {
    setIsActive(false);
  }

  const startLoader = () => {
    setIsActive(true);
  }

  return (
    <div className="featuresContainer" id='features' ref={refContainer} onMouseUp={() => setDragStart(false)} onTouchEnd={() => setDragStart(false)}>
      <div className="headerFeature">
        <div className="titles">
          <h2>Notre promesse</h2>
          <img src={underline} alt="underline"/>
          <p className="subtitle">
            <span>Aujourd’hui</span>  : Des tableaux <span onClick={() => {setExplainAnim(false); setExplainSelected(0)}} className={explainSelected === 0 ? 'word wordSelected' : 'word'}>dynamiques</span> , <span onClick={() => {setExplainAnim(false); setExplainSelected(1)}} className={explainSelected === 1 ? 'word wordSelected' : 'word'}>exportables</span> dans Excel, à partir de n’importe quels journal, balance ou grand-livre et <span onClick={() => {setExplainAnim(false); setExplainSelected(2)}} className={explainSelected === 2 ? 'word wordSelected' : 'word'}>quel que soit le format initial</span> du fichier Excel de la donnée comptable.
          </p>
          <p className="subtitle">
            <span>Demain</span>  : Des tableaux personnalisés, même avec des plans comptables étrangers, et une première incursion du côté du reporting financier ! 
          </p>
        </div>
        <div className="explainContainer">
          {windowWidth >= 1280 &&
            <svg className='firstEllipse' xmlns="http://www.w3.org/2000/svg" width="371" height="372" viewBox="0 0 371 372">
              <g id="Ellipse_12" data-name="Ellipse 12" fill="none" stroke="#c6c8c9" strokeWidth="0.5" strokeDasharray="10 8">
                <ellipse cx="185.5" cy="186" rx="185.5" ry="186" stroke="none"/>
                <ellipse cx="185.5" cy="186" rx="185.25" ry="185.75" fill="none"/>
              </g>
            </svg>   
          }
          {windowWidth >= 1280 &&
            <svg className='secondEllipse' xmlns="http://www.w3.org/2000/svg" width="371" height="372" viewBox="0 0 371 372">
              <g id="Ellipse_12" data-name="Ellipse 12" fill="none" stroke="#c6c8c9" strokeWidth="0.5" strokeDasharray="10 8">
                <ellipse cx="185.5" cy="186" rx="185.5" ry="186" stroke="none"/>
                <ellipse cx="185.5" cy="186" rx="185.25" ry="185.75" fill="none"/>
              </g>
            </svg>          
          }
          <div className="explain">
            {arrayExplain.map((explain, key) => {
              return (
                <div className={key === explainSelected ? "explainWrapper explainWrapperVisible" : "explainWrapper explainWrapperHidden"} key={key} >
                  <p className='title'>{explain.title}</p>
                  <p className='descriptionExplain'>{explain.description}</p>
                </div>
              )
            })}          
          </div>
        </div>
      </div>
      
      <div className="slider">
        {windowWidth >= 1280 && 
          <div className="sliderContainer" onMouseEnter={() => pauseLoader()} onMouseLeave={() => startLoader()}>
            <div className="sliderWrapper" ref={refSliderWrapper} onMouseDown={(e) => handleDragStart(e.screenX)} onTouchStart={(e) => handleDragStart(e.touches[0].clientX)}  onMouseMove={(e) => handleDrag(e.screenX)} onTouchMove={(e) => handleDrag(e.touches[0].clientX + 40)}>
              {arrayFeatures.map((item, key) => {
                return (
                  <div className="card" key={key}>
                    <div className={key > 3 ? "labelContainer labelContainerV1 " : "labelContainer"}>
                      <div className="label">
                        <div className="circle"></div>
                        <p className="labelName">{key > 3 ? 'À venir' : "Live"}</p>
                      </div>
                    </div>
                    <h3>{item.title}</h3>
                    <p className="descriptionFeature">{item.description}</p>
                  </div>
                )
              })}          
            </div>
          </div>
        }
        {windowWidth < 1280 &&
        <Swiper
          freeMode={true}
          mousewheel={{invert:true, forceToAxis: true}}
          navigation= {{
            nextEl: '.arrowRight',
            prevEl: '.arrowLeft',
          }}
          spaceBetween={20}
          slidesPerView={windowWidth > 600 ? 2 : 1}
        >
          {arrayFeatures.map((item, key) => {
              return (                
              <SwiperSlide key={key}>
                <div className="card" key={key}>
                  <div className={key > 3 ? "labelContainer labelContainerV1 " : "labelContainer"}>
                    <div className="label">
                      <div className="circle"></div>
                      <p className="labelName">{key > 3 ? 'À venir' : "BETA"}</p>
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                  <p className="descriptionFeature">{item.description}</p>
                </div>

              </SwiperSlide>
              )
            })} 
        </Swiper>
        }
        <div className="underSlider">
          <div className="arrows">
            <div className="arrow arrowLeft" onClick={() => handleSliderMove('left')} onMouseEnter={() => pauseLoader()} onMouseLeave={() => startLoader()}>
              <img src={leftArrow} alt="leftArrow"/>
            </div>
            <div className="arrow arrowRight" onClick={() => handleSliderMove('right')} onMouseEnter={() => pauseLoader()} onMouseLeave={() => startLoader()}>
              <img src={leftArrow} alt="leftArrow"/>
            </div>
          </div>
          {windowWidth >= 1280 &&
            <div className="loader">
              <svg>
                <circle className='countDownBackground' r="18" cx="20" cy="20"></circle>
              </svg>
              <svg>
                <circle ref={refCountDown} className='countDown' r="18" cx="20" cy="20"></circle>
              </svg>
            </div>
          }
        </div>
      </div>
      
      
    </div>
  );
};

export default Features;