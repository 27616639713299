import React from 'react';
import { Link } from "gatsby";

import ButtonArrow from '../../elements/ButtonArrow/index'

import spotLogo from '../../images/spot.svg'
import spotPlusLogo from '../../images/spotPlusLogo.png'
import premiumLogo from '../../images/premium.png'
import check from '../../images/check.svg'
import goldenCheck from '../../images/goldenCheck.png'
import round from '../../images/round.png'
import goldenRound from '../../images/goldenRound.png'

// Style
import './style.scss';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const CardOffer = ({index, offer, setFaqOffer}) => {
  
  return (
    <div className="cardContainer" key={index}>
        <div className={"labelContainer"}>
          <div className="label">
             <img src={round} alt="round"/>
            {offer.type === 'premium' ? 'AVEC ABONNEMENT' : 'SANS ABONNEMENT'}
          </div>
        </div>
        <div className="body">
        <div className='main'>
          <div className="title">
            <div className="main">
              {offer.type === 'premium' ?
                <img src={premiumLogo} alt="spot logo"/>
                : offer.type === 'spot' ?
                <img src={spotLogo} alt="spot logo"/>
                : <img src={spotPlusLogo} alt="spot logo"/>
              }
              <h3>{offer.title}</h3>
            </div>
            <p className='subtitle'>{offer.subtitle}</p>
          </div>
          {offer.type === 'spot' &&
            <div className='price'>
              <div className="priceNumberContainer">
                <p>A partir de </p>
                <div className="number">
                  <p className='priceNumber'>{offer.price}</p>
                  <p className='euro'>€</p>
                </div>
              </div>
              <p className="details">{offer.details}</p>
            </div>
          }
          {offer.type === 'premium' &&
            <div className="pricePremium">
              <p>Tarification personnalisée </p>
              <div className="number">
                <p className='numberPremium'>-</p>
              </div>
            </div>
          }
          {offer.features.map((feature, key) => {
            return (
              offer.type !== 'premium' && key === 0 ?
              <div>
                <div className="feature" key={key}>
                  {offer.type === 'premium' ? <img src={goldenCheck} alt="check"/> : <img src={check} alt="check"/> }
                  <Link to='/#faq' onClick={() =>  {offer.type === 'free' ? setFaqOffer([1, 0]) : setFaqOffer([2, 0])}}><p >{feature}</p></Link>
                </div>
                <p className="more">
                  <span>Télécharger un exemple</span> 
                  <a href={offer.type === 'spot' ? "/datapack.xlsx" : '/datapackFree.xlsx'} download target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36.907" height="36.907" viewBox="0 0 36.907 36.907">
                      <g id="xls" transform="translate(0.25 0.25)">
                        <g id="Groupe_688" data-name="Groupe 688">
                          <g id="Groupe_687" data-name="Groupe 687">
                            <path id="Tracé_154" data-name="Tracé 154" d="M6.068,0V7.585H0V21.237H6.068V36.407H27.305l9.1-9.1V0ZM1.517,19.72V9.1H24.271V19.72ZM27.305,34.262V27.305h6.957Zm7.585-8.474h-9.1v9.1H7.585V21.237h18.2V7.585H7.585V1.517H34.89Z" fill="#46b78e" stroke="#46b78e" strokeWidth="0.5"/>
                            <rect id="Rectangle_527" data-name="Rectangle 527" width="25.326" height="12.663" transform="translate(0.791 7.989)" fill="#46b78e"/>
                          </g>
                          <path id="Tracé_158" data-name="Tracé 158" d="M-17892.018-14670.144v9.238l9.264-9.238Z" transform="translate(17918.27 14696.086)" fill="#46b78e"/>
                        </g>
                        <g id="Groupe_690" data-name="Groupe 690" transform="translate(4.489 11.068)">
                          <g id="Groupe_689" data-name="Groupe 689">
                            <path id="Tracé_155" data-name="Tracé 155" d="M47.872,121.209l1.577-3.353H47.957l-.864,2.194-.874-2.194h-1.51l1.558,3.353-1.691,3.428h1.5l.97-2.3.977,2.3h1.539Z" transform="translate(-44.576 -117.856)" fill="#fdfdfd" stroke="#fdfdfd" strokeWidth="0.5"/>
                          </g>
                        </g>
                        <g id="Groupe_692" data-name="Groupe 692" transform="translate(11.204 11.068)">
                          <g id="Groupe_691" data-name="Groupe 691">
                            <path id="Tracé_156" data-name="Tracé 156" d="M111.32,123.516v-5.66h-1.368v6.781h3.788v-1.121Z" transform="translate(-109.952 -117.856)" fill="#fdfdfd" stroke="#fdfdfd" strokeWidth="0.5"/>
                          </g>
                        </g>
                        <g id="Groupe_694" data-name="Groupe 694" transform="translate(16.034 10.938)">
                          <g id="Groupe_693" data-name="Groupe 693">
                            <path id="Tracé_157" data-name="Tracé 157" d="M165.766,120.908a1.317,1.317,0,0,0-.2-.47,1.705,1.705,0,0,0-.394-.4,3.091,3.091,0,0,0-.635-.358l-1.054-.456a1.171,1.171,0,0,1-.57-.394.976.976,0,0,1-.133-.527,1.208,1.208,0,0,1,.033-.294.6.6,0,0,1,.122-.252.653.653,0,0,1,.242-.176.978.978,0,0,1,.38-.066.633.633,0,0,1,.57.261,1.385,1.385,0,0,1,.169.747H165.6v-.19a2.032,2.032,0,0,0-.157-.831,1.517,1.517,0,0,0-.437-.568,1.738,1.738,0,0,0-.665-.323,3.344,3.344,0,0,0-.84-.1,2.243,2.243,0,0,0-1.539.494,1.893,1.893,0,0,0-.56,1.5,2.371,2.371,0,0,0,.1.738,1.514,1.514,0,0,0,.328.56,2.059,2.059,0,0,0,.556.423,5.045,5.045,0,0,0,.788.334,5.245,5.245,0,0,1,.575.223,1.463,1.463,0,0,1,.375.242.762.762,0,0,1,.2.3,1.171,1.171,0,0,1,.062.394.772.772,0,0,1-.252.632.871.871,0,0,1-.565.2,1.137,1.137,0,0,1-.44-.076.7.7,0,0,1-.282-.211.774.774,0,0,1-.142-.328,2.375,2.375,0,0,1-.036-.431v-.2h-1.368v.275a1.964,1.964,0,0,0,.5,1.444,2.278,2.278,0,0,0,1.659.5,2.74,2.74,0,0,0,1.733-.491,1.866,1.866,0,0,0,.622-1.562A2.617,2.617,0,0,0,165.766,120.908Z" transform="translate(-161.312 -116.512)" fill="#fdfdfd" stroke="#fdfdfd" strokeWidth="0.5"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>

                </p>
              </div>              
              : 
              offer.type === 'premium' && key === 0 ?
              <div>
                <div className='incoming'>
                  <div className="feature">
                    <img src={check} alt="check"/>
                    <p><Link to='/#faq' onClick={() =>  setFaqOffer([2, 2])}><span className='textBold'>{offer.type === 'spot' ? '1 Workspace' : 'Workspaces illimités'}</span></Link></p>
                  </div>
                  <div className="more">
                    <p>- Intégralité des fonctionnalités de l'application</p>
                  </div>
                  <div className="more">
                    <p>- Import de datasources {offer.type === 'spot' ? 'limités' : 'illimités'}</p>
                  </div>
                </div>
                <div className="feature" key={key}>
                  <img src={check} alt="check"/>
                  <p>{feature}</p>
                </div>
              </div>
              :
              <div className="feature" key={key}>
                <img src={check} alt="check"/>
                <p>{feature}</p>
              </div>              
              
            )
          })}      
        </div>
        {offer.type === 'premium' ?
          <div className="buttonContainer">
            <Link to='/#contact'><ButtonArrow>Planifier une demo</ButtonArrow></Link>
          </div>
        :
          <div className="buttonContainer">
            <a href='https://spot.scorf.io/'><ButtonArrow>COMMENCER</ButtonArrow></a>
          </div>
        }
      </div>
    </div>
  );
};

export default CardOffer;