const arrayExplain = [
  {
    title: 'Dynamiques',
    description: "1. Plongez de l’agrégat le plus élevé jusqu’à l’écriture comptable la plus fine \n2. Adaptez les formats aux spécificités des métiers analysés \n3. Agrégez les entités du groupe pour avoir une vision cumulée"
  },
  {
    title: 'Exportables',
    description: "La donnée n’est pas simplement copiée-collée en valeur dans l’onglet Excel, mais est reconstruite à partir de formules qui sont visibles directement dans les cellules pour faciliter la prise en main et l’audit des exports."
  },
  {
    title: 'Quel que soit le format initial',
    description: "Nous comprenons les formats des extractions sur lesquels vous travaillez habituellement (Sage, Cegid, SAP etc.) et savons reconnaître une ligne de titre, un numéro de compte ou une cellule fusionnée."
  },
]

export default arrayExplain;