import React, { useState, useEffect, useRef } from 'react';
import { Link } from "gatsby";
import Button from '../../elements/Button/index'

// Style
import './style.scss';


import introductionImage from '../../images/introductionImage.png'

const jobArray = [
  "Entrepreneurs",
  "Directeurs Financiers",
  "Experts-Comptables",
  "Consultants en transaction",
  "Consultants en restructuring ",
]
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Introduction = ({setHeaderColor, setSelectedComponent}) => {
  
  const refContainer = useRef(null);

  const [windowWidth, setWindowWidth] = useState(1281);
  const [jobSelected, setJobSelected] = useState(0);
  const [startAnim, setStartAnim] = useState(false);


  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(jobSelected === jobArray.length - 1) {
        setJobSelected(0);
      }
      else {
        setJobSelected(jobSelected + 1);
      }      
    }, 3000);
    // return () => clearTimeout(timer);
  }, [jobSelected]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnim(true)     
    }, 4000);
    // return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, []);


  const handleScroll = () => {   
    if( refContainer.current && refContainer.current.getBoundingClientRect().y < 93) {
      setSelectedComponent(-1);
      if(refContainer.current.getBoundingClientRect().y >= 0) {
        setHeaderColor('transparent');
      }
      else {
        setHeaderColor('white');
      }
    }
  }

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }


  return (
    <div className="introductionContainer container" id='home' ref={refContainer}>
      <div className="content">
        <div className="imageContainer">
          <svg className='firstEllipse' xmlns="http://www.w3.org/2000/svg" width="371" height="372" viewBox="0 0 371 372">
            <g id="Ellipse_12" data-name="Ellipse 12" fill="none" stroke="#c6c8c9" strokeWidth="0.5" strokeDasharray="10 8">
              <ellipse cx="185.5" cy="186" rx="185.5" ry="186" stroke="none"/>
              <ellipse cx="185.5" cy="186" rx="185.25" ry="185.75" fill="none"/>
            </g>
          </svg>
          <svg className='secondEllipse' xmlns="http://www.w3.org/2000/svg" width="371" height="372" viewBox="0 0 371 372">
            <g id="Ellipse_12" data-name="Ellipse 12" fill="none" stroke="#c6c8c9" strokeWidth="0.5" strokeDasharray="10 8">
              <ellipse cx="185.5" cy="186" rx="185.5" ry="186" stroke="none"/>
              <ellipse cx="185.5" cy="186" rx="185.25" ry="185.75" fill="none"/>
            </g>
          </svg>  
          <img src={introductionImage} alt="computer" />
        </div>
        <div className='introductionText'>
          <h1>
            {jobArray.map((job, key) => {
              return (
                <span className={key === jobSelected ? "job jobVisible" : "job jobHidden"} key={key}>{job}</span>
              )
            })}
          </h1>
          <p className='subtitle'>
            {windowWidth > 600 && 
            `Nous n'allons pas révolutionner votre métier,
            livrer vos courses en 10 minutes,  
            \rvous ouvrir en grand les portes du Machine Learning.`}
            {windowWidth < 601 && 
            `Nous n'allons pas révolutionner votre métier, livrer vos courses en 10 minutes, vous ouvrir en grand les portes du Machine Learning.`}
          </p>
          <p className='explanation'>
            En revanche, pour toutes les fois où vous devrez construire, manipuler et partager des états financiers sur la base de FECs, Grands Livres ou Balances Générales, nous avons la meilleure solution.  
          </p>
          <div className="buttons">
            <Link to='/#offer'><Button color='white'>NOS OFFRES</Button></Link>
            <Link to='/#contact'><Button color='transparent'>NOUS CONTACTER</Button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;