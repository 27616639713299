import React from 'react';

// Style
import './style.scss';

/**
 * Small Scorf Loader
 */

const SmallLoader = () => {
  return (
    <div className="smallLoaderContainer">
      <svg version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 251.4 256.5" width="251.39999389648438" height="256.5">
        <g id="Calque_2_1_">
          <g id="Calque_8">
            <path
              className="st0 svg-elem-1"
              d="M161,210.7c-25.8,0.2-47.7,18.8-51.9,44.2c4.6,0.5,9.3,0.8,14,0.8c3.9,0,7.8-0.2,11.6-0.6
                    c3.7-11.6,14.4-19.6,26.6-19.6h30.5c10.7-6.9,20.3-15.3,28.5-24.9L161,210.7L161,210.7z"
            />
          </g>
          <g id="Calque_11">
            <path
              className="st0 svg-elem-2"
              d="M133.9,162.9c-41,0.3-76.2,29.1-84.7,69.2c7.3,5.2,15.1,9.6,23.3,13.2c2.5-32.4,29.5-57.4,62-57.5h101.4
                    c4.2-7.9,7.6-16.3,10-24.9L133.9,162.9L133.9,162.9z"
            />
          </g>
          <g id="Calque_9">
            <path
              className="st0 svg-elem-3"
              d="M161,45.2h58.8c-8.3-9.7-18.1-18.1-28.9-24.9h-29.6c-12,0-22.6-7.7-26.5-19c-3.9-0.4-7.8-0.6-11.7-0.6
                    c-4.7,0-9.4,0.3-14,0.8C113.6,26.7,135.5,45.1,161,45.2z"
            />
          </g>
          <g id="Calque_10">
            <path
              className="st0 svg-elem-4"
              d="M133.9,92.8h111.7c-2.5-8.6-5.9-17-10.1-24.9h-101c-32.1-0.1-59-24.6-61.9-56.6c-8.2,3.5-15.9,7.9-23.2,13
                    C58.1,64,93.2,92.4,133.9,92.8z"
            />
          </g>
          <g id="Calque_2-2">
            <path
              className="st0 svg-elem-5"
              d="M78.9,140.5H250c0.4-4.1,0.6-8.2,0.6-12.3s-0.2-8.5-0.6-12.6H79.4c-32.5,0-59.8-24.7-63-57.1
                    C9.6,69,4.3,80.4,0.8,92.3C15.8,121.7,45.9,140.3,78.9,140.5z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SmallLoader;
