import React, { useState, useEffect, useRef } from 'react';
import CardOffer from './card';

// Style
import './style.scss';
import 'swiper/swiper.scss';

// Images
import leftArrow from '../../images/leftArrow.png'
import underline from '../../images/bigunderline.svg';

// Datas
import arrayOffer from './data';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';

SwiperCore.use([Navigation, Mousewheel])

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Offer = ({setHeaderColor, setSelectedComponent, setFaqOffer}) => {
  const refContainer = useRef(null);

  const [windowWidth, setWindowWidth] = useState(1280);

  const handleScroll = () => {
    if(refContainer.current && refContainer.current.getBoundingClientRect().y < 93) {
      setHeaderColor('dark');
      setSelectedComponent(2);
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
    document.addEventListener('scroll', handleScroll)
  }, []);

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }

  return (
    <div className="offerContainer" id='offer' ref={refContainer}>
      <div className="titles">
        <h2>Nos offres !</h2>
        <img src={underline} alt="underline"/>
      </div>
      {windowWidth >= 1280 &&
        <div className="cards">
            {arrayOffer.map((offer, key) => {
              return (
                  <CardOffer offer={offer} key={key} setFaqOffer={setFaqOffer}/>
              )
            })}
        </div>
      }
      {windowWidth < 1280 &&
        <div className="cards">
          <Swiper
            freeMode={true}
            mousewheel={{invert:true, forceToAxis: true}}
            navigation= {{
              nextEl: '.arrowRight',
              prevEl: '.arrowLeft',
            }}
            spaceBetween={20}
            slidesPerView={1}
          >
            {arrayOffer.map((offer, key) => {
              return (
                <SwiperSlide key={key} >
                  <CardOffer key={key} index={key} offer={offer} setFaqOffer={setFaqOffer}/>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      }
      {windowWidth < 1280 &&
        <div className="arrows">
          <div className="arrow arrowLeft" >
            <img src={leftArrow} alt="leftArrow"/>
          </div>
          <div className="arrow arrowRight" >
            <img src={leftArrow} alt="rightArrow"/>
          </div>
        </div>
      }
    </div>
  );
};

export default Offer;