const arrayFAQ = [
  [
    {
      question: 'Qui sommes-nous ?',
      answer: `Faire la chronique d’une entreprise à partir de milliers et de milliers de chiffres, détricoter, décortiquer, dévoiler l’histoire qui se cache derrière les lignes hermétiques d’une base de données comptable : voilà ce qui nous anime et ce qui fédère l’équipe ! 

Équipe constituée à l’origine de deux consultants en « Transaction Services » qui se sont rencontrés chez EY avant de rejoindre trois ans plus tard Eight Advisory. Ils y auront passé cinq années, à travailler auprès de fonds d’investissement et de dirigeants sur leurs opérations d’acquisitions et de cessions d’entreprises. Et près de 60 transactions plus tard, une expertise commence à se dessiner : analyser les données brutes d’une entreprise pour les restituer dans un format intelligible.`,
    },
    {
      question: 'Comment la confidentialité des données est-elle garantie ? ',
      answer: `La confidentialité des données est la pierre angulaire de la stratégie de Scorf ; c’est grâce à cette attention très stricte aux respects des standards professionnels que nous gagnons la confiance de nos clients :
      \n 1. La données comptable est hébergée dans des Workspaces personnalisés auxquels seul leur propriétaire a accès. Ces Workspaces sont hébergés en France sur des serveurs Microsoft Azure dont le niveau de sécurité est reconnu par le marché comme étant l’un des plus élevés.
      \n 2. Préalablement à l’import des sources comptables, il est possible si nécessaire de demander la signature d’un accord de confidentialité (NDA).
      \n 3. Dans le cadre de l'offre Spot nous vous permettons de supprimer des serveurs à tout moment l’information importée dans votre Workspace. `
    },
    {
      question: 'Quel est le format idéal de la donnée comptable pour Scorf ?',
      answer: `Scorf a été spécifiquement conçu pour manipuler les FEC et les Grands-Livres.   

      Le FEC, ou Fichier des Ecritures Comptables, est un fichier qui contient l’ensemble des écritures enregistrées dans la comptabilité pendant un exercice fiscal. La moindre palpitation comptable de l’entreprise au cours d’une période donnée y est inscrite : paiement des salaires, achat d’une fourniture, enregistrement d’un avoir, tout y figure selon une grammaire précise et très normée.  
      
      Pour faciliter et digitaliser les contrôles fiscaux, l’Etat a rendu obligatoire depuis le 01 janvier 2014 la production de ce fichier pour toutes les entreprises de France, sans aucune distinction de taille ou de chiffre d’affaires. Il a en même temps strictement encadré la présentation de ce fichier : le nom des colonnes et leur nombre, ainsi que le format des dates est déterminé par le texte de loi (dont voici le lien <a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000027804775/' target='_blank'>https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000027804775/</a>, qui vous présente notamment les colonnes obligatoires).  
      
      Ni Excel – dont l’efficacité commence à décliner dès qu’il s’agit de fichier de plus de 100k lignes, ni les outils de Business Intelligence – dont la vocation généraliste les empêche de « comprendre » la donnée qu’ils traitent, ne sont parfaitement adaptés pour exploiter pleinement la richesse d’information contenue dans les FEC. D’où la création de Scorf !  
                   
      Il existe une seconde base de données comptable partagée par toutes les entreprises : le Grand Livre, ni plus ni moins qu’un FEC mais sans caractère officiel. Il est plus complexe à lire car il ne répond à aucune norme officielle : il intègre les spécificités de nommage ou de construction de chaque ERP ou système comptable. En revanche, il est disponible tout le temps, à n’importe quel moment de l’exercice contrairement au FEC officiel qui n’est produit qu’après validation et clôture des écritures. Dans la plupart des systèmes comptables il est possible d’extraire un Grand Livre qui reprenne les conventions du FEC, c’est un Grand-Livre au « format FEC » et Scorf pourra exploiter pleinement tout ce qui s’y trouve enregistré.  `,
    },
    {
      question: 'Comment obtenir son FEC ou son Grand-Livre dans le bon format?   ',
      answer: `Lorsque ma comptabilité est externalisée, je dois me tourner vers mon expert-comptable pour récupérer FEC et Grands Livres. Un bon Grand Livre est un Grand Livre qui inclut :  
      \n    - la reprise des soldes de clôture N-1 pour l’ensemble des comptes de bilan (les "à nouveau") ; 
      \n    - les écritures passées sur les comptes auxiliaires (#401 "Fournisseurs" et #411 "Clients" notamment) qui ne doivent pas être centralisées
       (ces dernières, souvent volumineuses, peuvent parfois être regroupées dans une seule écriture - on dit alors qu’elles sont « centralisées ») 
      \n    - une colonne avec le numéro de l’écriture comptable ;
      \n    - une colonne avec les écritures de lettrage.
      \nN’hésitez pas à copier-coller ces critères pour préciser la demande à votre expert-comptable.
      \nLorsque ma comptabilité est internalisée, je dois extraire moi-même mon Grand Livre ou mon FEC selon les spécificités du logiciel comptable que j’utilise. Néanmoins les critères cités ci-dessus s’appliquent également. Nous préparons une série d’explications pour les différents ERP du marché. En attendant, voici un lien qui pourra peut-être vous faciliter la tâche : <a href='https://www.inqom.com/blog/exportez-vos-fec-de-vos-logiciels-de-production-comptables' target='_blank'>https://www.inqom.com/blog/exportez-vos-fec-de-vos-logiciels-de-production-comptables</a> (merci Inqom ! )        `
    },

  ],
  [
    {
      question: 'Qu’est-ce que l’offre Spot ?  ',
      answer: `Cette offre est construite pour des utilisations ponctuelles : repreneur ou dirigeant d’entreprise à la recherche d’acquisitions ou experts-comptables en mission de due diligence par exemple. Cette offre donne droit: 
      \n    - Un datapack Excel disponible dans la journée contenant les P&L, Bilan et Cash-Flow de toutes les entités en vision annuelle  
      \n    - et mensuelle si la source de données le permet. Si la cible est un groupe de plusieurs entités, le datapack contiendra également des tableaux agrégés mais sans l’élimination des écritures intercompagnies. Lorsque les données mensuelles sont disponibles nous construisons également un 4e tableau, le Cash Report qui présente les entrées et sorties de cash pour chacun des mois de la période.
      \n    - à l’application à travers un Workspace dédié permettant d’utiliser l’intégralité des fonctionnalités de l’application et d’importer pendant 48h toutes les données comptables souhaitées (disponible prochainement) couleur orange. 
      \n    - Si les utilisateurs souhaitent approfondir la compréhension des chiffres présentés dans le datapack, ils pourront demander un accompagnement personnalisé.`
    },
    {
      question: 'Comment ça marche ?',
      answer: `Sélectionnez l’offre Spot et créez-vous un compte avec une adresse mail et un mot de passe. Ces identifiants vous donneront accès à votre Workspace personnalisé et sécurisé dans lequel vous pourrez déposer vos documents comptables : 
      \n\t - FEC, Grands Livres, Balances Générales français et tout type de journaux (paie, achats, vente) sous format Excel ou csv.
         - Nous acceptons également les fichiers PDF mais ces derniers entraînent un surcoût de traitement de 25%.
      \nVous choisissez un certain nombre d’options pour le datapack (voulez-vous exporter des tableaux avec tous les mouvements de la période ou juste les soldes finaux par exemple) et précisez succinctement la demande (est-ce que les documents comptables concernent plusieurs entreprises, le datapack doit-il inclure une vision agrégée etc.). Une fois la demande validée, nous traitons les documents dans la journée et vous serez notifiés par mail une fois le datapack disponible au téléchargement depuis votre Workspace.
      \nLe paiement intervient au moment du téléchargement. \nSi certaines modifications sont nécessaires dans les tableaux du datapack, l’offre inclut un aller-retour pour prendre en compte les changements demandés. Pour des demandes plus avancées – par exemple la construction de marges, vous pourrez avoir recours à un accompagnement personnalisé.`
    },
    {
      question: 'Qu’est-ce qu’un Workspace ? ',
      answer: "Un Workspace est un espace de travail propre à un utilisateur dans lequel il va importer la donnée comptable à traiter. Un Workspace correspond à une « mission » i.e à une entité ou à un groupe d’entités juridiques à analyser. \n \nDans le cadre de l’offre Spot, le Workspace est limité sur le plan de l’import de datasources."
    },
    {
      question: 'A quel moment suis-je facturé ?',
      answer: "Le règlement par carte bancaire ou virement bancaire du datapack permet de débloquer son téléchargement depuis votre Workspace"
    },
  ],
]

export default arrayFAQ;