const arrayFeatures = [
  {
    title: 'Etats Financiers',
    description: "Construisez en quelques minutes le Compte de Résultat, le Bilan et le Cash-Flow de l'entreprise sur la base de ses FEC, Grands Livres ou Balances Générales. \nEt surtout: ne perdez plus de temps à structurer et préparer en amont les fichiers, Scorf s'occupe de tout ! "
  },
  {
    title: 'Dynamiques',
    description: "Modifiez facilement vos tableaux pour adapter leur présentation aux différentes spécificités métiers : créez une marge brute qui réponde à la réalité de l'activité ; ajoutez et effacez des postes de l'EBITDA pour obtenir la vision la plus juste etc. "
  },
  {
    title: '"Mapping"',
    description: 'Créez vos propres "mapping" et générez tous les tableaux nécessaires à vos analyses.'
  },
  {
    title: 'Export',
    description: "Exportez simplement dans Excel tous vos tableaux : facilitez-vous la vie grâce à un format clair et des données qui ne sont plus \"collées en valeur\" mais générées par de véritables formules Excel facilement compréhensibles !"
  },
  {
    title: 'Tableaux de détail',
    description: "Construisez l'ensemble des tableaux de détail du bilan de l'entreprise : variation des immobilisations, évolution du BFR, délais de paiement clients et fournisseurs !"
  },
  {
    title: 'Partagez',
    description: 'Partagez toutes vos analyses gràce à un simple lien.'
  },
  {
    title: 'Data Mining',
    description: "Décupler la profondeur de vos analyses grâce à des fonctionalités uniques: reconnaissance des schémas comptables, affichage des contreparties des écritures, identification des écritures dont la somme est égale à un montant donné et beaucoup d'autres encore ! "
  },
]

export default arrayFeatures;